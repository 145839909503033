<template>
  <div>
    <el-card>
      <nav class="out">
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >活动日期：</label
        >
        <nav class="input">
          <el-date-picker
            v-model="eventdate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >发布时间：</label
        >

        <nav class="input">
          <el-date-picker
            v-model="publishdate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="medium"
          />
        </nav>
        <nav class="input">
          <el-input v-model="title" size="medium" placeholder="输入标题" />
        </nav>
        <nav class="input" style="width: 150px;">
          <el-input v-model="authName" size="medium" placeholder="编辑人员" />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="checkStatus" placeholder="审核状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="未通过" :value="0" />
            <el-option label="通过" :value="1" />
          </el-select>
        </nav>

        <nav class="input" style="width: 150px">
          <el-select
            v-model="publishStatus"
            placeholder="发布状态"
            size="medium"
          >
            <el-option label="全部" :value="-1" />
            <el-option label="未发布" :value="0" />
            <el-option label="发布" :value="1" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="acId" placeholder="分类" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(i, index) in publi"
              :key="index"
              :label="i"
              :value="index"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="ok"
            placeholder="显示状态"
            size="medium"
            @change="getok"
          >
            <el-option label="全部" :value="-1" />
            <el-option label="热门" :value="1" />
            <el-option label="推荐" :value="0" />
          </el-select>
        </nav>
        <nav class="input">
          <!-- <el-button
   @click="reset"
   size="small"
 >重置</el-button> -->
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="60" label="编号" sortable="custom" />
        <el-table-column label="海报" width="180">
          <template slot-scope="ew">
            <!-- <img
              :src="baseurl + yu + ew.row.banner"
              alt=""
              style="width: 120px"
            /> -->
            <el-image
              style="width: 120px"
              :src="baseurl + yu + ew.row.banner"
              :preview-src-list="[baseurl + yu + ew.row.banner]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="分类" width="100">
          <template slot-scope="ew">
            {{ ew.row.acId | getacid }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="150">
          <template slot-scope="ew">
            <p>
              <a
                :href="`https:/${yu}/activity/${ew.row.id}.html`"
                target="“_blank”"
                style="  text-decoration: underline;"
                >{{ ew.row.title }}</a
              >
            </p>
            <a
              v-if="!!ew.row.otherUrl"
              :href="ew.row.otherUrl"
              target="“_blank”"
              >(活动链接)</a
            >
            <p v-if="ew.row.preview">
              <a :href="ew.row.preview" target="“_blank”">
                (PC预览)
              </a>
            </p>
            <p v-if="ew.row.previewSunCode">
              <a :href="baseurl + yu + ew.row.previewSunCode" target="“_blank”"
                >(小程序预览)</a
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="applyNum" label="报名人数" width="150">
          <template slot-scope="{ row }">
            <p>{{ row.applyNum + '/' + row.people }}</p>
            <p
              style="color:tan;cursor: pointer;"
              @click="toList(row)"
              v-show="$store.state.btn_if.indexOf('activity_apply_list') != -1"
            >
              名单管理
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="authName" label="编辑人员" width="120" />

        <!-- <el-table-column prop="people" label="限制人数" width="120" /> -->

        <el-table-column
          prop="orderNum"
          align="right"
          width="100"
          sortable="custom"
        >
          <template #header>
            <span>排序</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                :ref="'INPUTFOCUS' + $index"
                v-model="row.orderNum"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('activity_list_order') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('activity_list_order') != -1 &&
                  showinput(row, 'INPUTFOCUS' + $index)
              "
            >
              {{ row.orderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="beginTime" label="状态" width="100">
          <template slot-scope="ew">
            <el-tag v-if="ew.row.publishStatus == 1" type="warning"
              >已发布</el-tag
            >
            <el-tag v-if="ew.row.publishStatus == 0" type="warning"
              >已下架</el-tag
            >
            <el-tag v-if="ew.row.checkStatus == 1" type="warning"
              >已审核</el-tag
            >
            <el-tag v-if="ew.row.checkStatus == 0" type="warning"
              >未通过</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="id" label="显示位置">
          <template #header>
            <span>显示位置</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                【推荐】显示到活动列表页头部banner轮播；【热门】PC端侧栏两条热门活动。显示顺序都按照排序值排序
              </div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="ew">
            <el-tag v-if="ew.row.recommend" type="warning">热门</el-tag>
            <el-tag v-if="ew.row.top" type="warning">推荐</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="views" label="浏览量"> </el-table-column>
        <el-table-column prop="endTime" label="活动时间">
          <template slot-scope="scope">
            {{ scope.row.beginTime }} 至 {{ scope.row.endTime }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间" />

        <el-table-column prop="beginTime" label="操作" width="180">
          <template slot-scope="ew">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="edit(ew.row.id, ew.row.location)"
              v-show="$store.state.btn_if.indexOf('activity_list_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="del(ew.row.id)"
              v-show="$store.state.btn_if.indexOf('activity_list_delete') != -1"
              >删除</el-button
            >

            <el-button
              v-if="ew.row.checkStatus == 0"
              type="text"
              icon="el-icon-s-check"
              size="mini"
              class="yellow"
              @click="shen(ew.row.id, ew.row.checkStatus)"
              v-show="$store.state.btn_if.indexOf('activity_list_check') != -1"
              >审核通过</el-button
            >
            <el-button
              v-else
              type="text"
              icon="el-icon-s-check"
              size="mini"
              class="yellow"
              @click="shen(ew.row.id, ew.row.checkStatus)"
              v-show="$store.state.btn_if.indexOf('activity_list_check') != -1"
              >审核不通过</el-button
            >
            <el-button
              v-if="ew.row.publishStatus == 0"
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              class="green"
              @click="fabu(ew.row.id, ew.row.publishStatus)"
              v-show="
                $store.state.btn_if.indexOf('activity_list_publish') != -1
              "
              >发布</el-button
            >
            <el-button
              v-else
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              class="green"
              @click="fabu(ew.row.id, ew.row.publishStatus)"
              v-show="
                $store.state.btn_if.indexOf('activity_list_publish') != -1
              "
              >取消发布</el-button
            >
            <el-button
              v-if="!ew.row.recommend"
              type="text"
              icon="iconfont icon-remen"
              size="mini"
              class="a4"
              @click="share(ew.row, 1)"
              v-show="
                $store.state.btn_if.indexOf('activity_list_recommend') != -1
              "
              >&nbsp;热门</el-button
            >
            <el-button
              v-if="ew.row.recommend"
              type="text"
              icon="iconfont icon-remen"
              size="mini"
              class="a4"
              @click="share(ew.row, 0)"
              v-show="
                $store.state.btn_if.indexOf('activity_list_recommend') != -1
              "
              >&nbsp;取消热门</el-button
            >
            <el-button
              v-if="!ew.row.top"
              type="text"
              icon="el-icon-share"
              size="mini"
              class="a5"
              @click="hot(ew.row, 1)"
              v-show="$store.state.btn_if.indexOf('activity_list_top') != -1"
              >推荐</el-button
            >
            <el-button
              v-if="ew.row.top"
              type="text"
              icon="el-icon-share"
              size="mini"
              class="a5"
              @click="hot(ew.row, 0)"
              v-show="$store.state.btn_if.indexOf('activity_list_top') != -1"
              >不推荐</el-button
            >
            <el-button
              type="text"
              icon="el-icon-film"
              size="mini"
              style="color:#0d86ff"
              @click="toChannel(ew.row)"
              v-show="
                $store.state.btn_if.indexOf('activity_channel_list') != -1
              "
              >渠道管理</el-button
            >

            <el-button
              type="text"
              icon="el-icon-s-flag"
              size="mini"
              style="color:orange"
              @click="toauthorization(ew.row)"
              v-show="
                $store.state.btn_if.indexOf('activity_manager_auth') != -1
              "
              >活动授权</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page="currentPage"
          background
          :page-sizes="[3, 5, 10, 15]"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
// import quillConfig from '../../quill'
import $ from 'jquery'
let that
export default {
  filters: {
    getacid(val) {
      console.log(that.publi)
      var sd = that.publi[val]
      return sd
    }
  },
  data() {
    return {
      acId: '',
      btn: true,
      paiform: {},
      list: '',
      form: {
        title: '',
        time: [],
        beginTime: '',
        endTime: '',
        city: '',
        detailAddress: '',
        banner: '',
        organizers: '',
        acId: '',
        people: 0,
        content: '',
        province: '',
        regUrl: '',
        str: ''
      },
      eventdate: [],
      publishdate: [],
      title: '',
      authName: '',
      publishStatus: '',
      checkStatus: '',
      huodong: [],
      province: [],
      city: [],
      currentPage: 1,
      pagesize: 10,
      tableData: [],
      total: 0,
      dialogVisible: false,
      dialogVisiblex: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      options: [],
      header: {
        Token: sessionStorage.getItem('token')
      },
      str: '',
      strs: '',
      publi: [],
      order: '',
      ok: '',
      strok: '',
      form_2: {
        page: 1,
        pageSize: 10,
        eventDate: '',
        publishDate: '',
        title: '',
        publishStatus: '',
        checkStatus: '',
        acId: '',
        order: ''
      }
    }
  },
  updated() {
    console.log(this.tableData)
  },
  beforeCreate() {
    that = this
  },
  created() {
    this.getlist()
  },
  methods: {
    reset() {
      this.eventdate = []
      this.publishdate = []
      this.title = ''
      this.checkStatus = ''
      this.publishStatus = ''
      this.acId = ''
      this.ok = ''
      this.strok = ''
      this.currentPage = 1
      this.authName = ''
      this.getlist()
    },
    toChannel(row) {
      this.$router.push({
        path: '/activity_channel',
        query: {
          id: row.id
        }
      })
    },
    getok() {
      if (this.ok == 0) {
        this.strok = { top: 1 }
      } else if (this.ok == 1) {
        this.strok = { recommend: 1 }
      } else {
        this.strok = { recommend: '', top: '' }
      }
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },

    async toList(row) {
      let {
        data: res
      } = await this.$http.post('/admin/Activity/checkPermission', {
        id: row.id
      })
      if (res.errorCode == 200) {
        this.$router.push({
          path: '/activity_num',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error(res.message)
      }
    },
    toauthorization(row) {
      this.$router.push({
        path: '/activity_authorization',
        query: {
          id: row.id
        }
      })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'activity_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.tableData = [...this.tableData]
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
      // this.$refs[refName].focus()
      // this.$refs[refName].focused = true

      // this.tableData.forEach(item=>{
      //  if(item.id==id){
      //    item.pai=true
      //  }
      //  this.$forceUpdate()
      //  })
    },
    async handleInputBlur(row) {
      const { id, orderNum } = row
      this.paiform.id = id
      this.paiform.orderNum = orderNum

      try {
        const { data: res } = await this.$http.post(
          '/admin/Activity/sort',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        // this.$message.error(e)
      }
      console.log(this.tableData)
    },
    share(row, el) {
      this.$http
        .post('/admin/Activity/recommend', {
          id: row.id,
          recommend: el
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    hot(row, el) {
      this.$http
        .post('/admin/Activity/top', {
          id: row.id,
          top: el
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    subimt() {
      if (this.btn) {
        this.form_2 = {
          page: 1,
          pageSize: this.pagesize,
          eventDate: this.eventdate ? this.eventdate.join('~') : '',
          publishDate: this.publishdate ? this.publishdate.join('~') : '',
          title: this.title,
          publishStatus: this.publishStatus,
          checkStatus: this.checkStatus,
          acId: this.acId,
          order: this.order,
          authName: this.authName,
          ...this.strok
        }
        this.$http
          .get('/admin/Activity/getList', {
            params: {
              ...this.form_2
            }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.tableData = res.data.list
              this.total = res.data.totalCount
              this.btn = true
            } else {
              this.$message.error(res.message)
            }
          })
      }
      this.btn = false
    },
    getlist() {
      this.$http
        .get('/admin/Activity/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            eventDate: this.eventdate.join('~'),
            publishDate: this.publishdate.join('~'),
            title: this.title,
            publishStatus: this.publishStatus,
            checkStatus: this.checkStatus,
            acId: this.acId,
            order: this.order,
            authName: this.authName,
            ...this.strok
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.tableData = res.data.list
            this.total = res.data.totalCount
            // this.tableData.forEach((item) => {
            //   this.pai.push({ id: item.id, pai: false })
            // })
            console.log(this.pai)
          } else {
            this.$message.error(res.message)
          }
        })
      this.$http
        .get('/admin/ActivityCategory/getList')
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.publi = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },

    // getxiu() {
    //   this.$http.get
    // },
    fabu(id, pu) {
      if (pu == 1) {
        pu = 0
      } else {
        pu = 1
      }
      this.$confirm('请确认无误后点确定！ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post('/admin/Activity/publish', {
              id: id,
              publishStatus: pu
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                console.log(res)
                // location.reload()
                this.getlist()
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    shen(id, shen) {
      if (shen == 1) {
        shen = 0
      } else {
        shen = 1
      }
      this.$http
        .post('/admin/Activity/check', {
          id: id,
          checkStatus: shen
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addedit() {
      // this.dialogVisible = true
      sessionStorage.setItem('flag', false)
      this.$http
        .post('/admin/Activity/edit', this.form)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.reload()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    add() {
      // console.log(1);
      this.dialogVisible = true
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.$http
        .get('/admin/Activity/getList', {
          params: {
            ...this.form_2,
            page: this.currentPage
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.tableData = res.data.list
            this.total = res.data.totalCount
            // this.tableData.forEach((item) => {
            //   this.pai.push({ id: item.id, pai: false })
            // })
            console.log(this.pai)
          } else {
            this.$message.error(res.message)
          }
        })

      // this.getlist()
    },
    del(id) {
      // console.log(id)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/Activity/del', {
              params: {
                id: id
              }
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    edit(id, lo) {
      // this.form.id = id
      sessionStorage.setItem('flag', false)
      var gu = {
        lat: lo.split(',')[0],
        lng: lo.split(',')[1]
      }
      sessionStorage.setItem('center', JSON.stringify(gu))
      this.$router.push({
        path: '/activity_add',
        query: {
          id: id
        }
      })
      // this.$http
      //   .get('/admin/Activity/getById?id=' + id)
      //   .then(({ data: res }) => {
      //     console.log(res.data)
      //     var time = new Date(res.data.beginTime)
      //     var time2 = new Date(res.data.endTime)
      //     this.form.time = [time, time2]
      //     // console.log(this.form.time)
      //     this.$http
      //       .get('/admin/Area/getList?code=330300')
      //       .then(({ data: res }) => {
      //         this.options = res.data
      //         this.form.city = Number(this.form.city)
      //         this
      //       })

      //     if (res.errorCode == 200) {
      //       this.dialogVisiblex = true
      //       this.form = res.data
      //     } else {
      //       this.$message.error(res.message)
      //     }
      //   })f
    }
  },
  computed: {
    lasturl() {
      return function(val) {
        if (val) {
          return '/yfchuhai.com/dedecms'
        } else {
          return ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btnAdd {
  margin-bottom: 50px;
}

.left {
  border-left: none;
}

.in {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  margin-left: 30px;
  padding-left: 30px;
  outline: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.biaodan {
  margin-top: 50px;

  .left {
    float: left;
    width: 60%;

    span {
      display: inline-block;
      width: 80px;
      margin-right: 4px;
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    width: 40%;

    .el-tag {
      width: 97px;
      margin-right: 5px;
      text-align: center;
    }
  }
}

.skyblue {
  color: skyblue;
}

.green {
  color: green;
}

.yellow {
  color: pink;
}

.red {
  color: red;
}

.a4 {
  color: #f96535;
}

.a5 {
  color: #ae09b5;
}

.el-tag--warning {
  margin: 5px;
}
</style>
